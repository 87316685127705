import { AbstractControl, ValidatorFn } from '@angular/forms';

export function decimalLimitValidator(integerLength: number, decimalLength: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const numberRegex = /^[0-9*z.?[0-9]+$/;
    const isNumber: boolean = numberRegex.test(control.value);
    const value = Number(control.value);

    if (!value || !isNumber) {
      return null;
    }

    const stringValue = value.toString();
    const decimalParts = stringValue.split('.');
    const integerPart = decimalParts[0];
    const decimalPart = decimalParts[1];

    if (value < 0) {
      return { negativeDecimal: true };
    }

    if (integerPart.length > integerLength) {
      return { limitExceeded: true };
    }

    if (decimalPart && decimalPart.length > decimalLength) {
      return { decimalLimit: true };
    }

    return null;
  };
}
