import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './containers/landing/landing.component';
import { MsalGuard } from '@azure/msal-angular';
import { UnexpectedErrorComponent } from './shared/unexpected-error/unexpected-error.component';
import { InternalUserAuthGuard } from './guards/internal-user-auth.guard';
import { ExternalUserAuthGuard } from './guards/external-user-auth.guard';
import { RequestAccountComponent } from './containers/request-account/request-account.component';
import { RedirectComponent } from './containers/redirect/redirect.component';
import { AccessibilityComponent } from './containers/accessibility/accessibility.component';
import { ErrorComponent } from './shared/error/error.component';
import { PermissionsErrorComponent } from './shared/permissions-error/permissions-error.component';
import { NotFoundErrorComponent } from './shared/not-found-error/not-found-error.component';

const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'redirect', component: RedirectComponent },
  {
    path: 'request-an-account',
    component: RequestAccountComponent,
    title: 'Request an account - Data Upload - UKHO'
  },
  {
    path: 'internal',
    loadChildren: () => import('./internal/internal.module').then((m) => m.InternalModule),
    canActivate: [MsalGuard, InternalUserAuthGuard]
  },
  {
    path: 'external',
    loadChildren: () => import('./external/external.module').then((m) => m.ExternalModule),
    canActivate: [MsalGuard, ExternalUserAuthGuard]
  },
  { path: 'accessibility', component: AccessibilityComponent, title: 'Accessibility statement - Data Upload - UKHO' },
  { path: 'error', component: ErrorComponent, title: 'Error' },
  { path: 'permissions-error', component: PermissionsErrorComponent, title: 'Permissions error' },
  { path: 'not-found-error', component: NotFoundErrorComponent, title: 'Not found error' },
  { path: 'unexpected-error', component: UnexpectedErrorComponent, title: 'Error' },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
