import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable()
export class PreventDirectNavigationGuard {
  constructor(private _router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Promise<boolean> | boolean> {
    return this.checkDirectAccess(state.url).pipe(
      catchError(() => {
        return of(true);
      }),
      map((isDirectAccess) => {
        // TODO: removed as e2e aren't configured to work with this as of yet.
        // const isReload =
        //   (performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming).type === 'navigate';

        if (isDirectAccess) {
          // return this._router.navigate(['/external/dashboard']);
        }

        return true;
      })
    );
  }

  private checkDirectAccess(url: string): Observable<boolean> {
    const isDirectAccess = url !== '/' && !this._router.navigated;
    return of(isDirectAccess);
  }
}
