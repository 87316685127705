<div *ngIf="(isAuthenticated | async) === false || (isAuthenticated | async) === null" class="is-relative">
  <div class="top-tile">
  </div>
  <div class="tile is-12 is-relative mt-6">
    <article class="login">
      <section class="section mt-6">
        <h1>UKHO Data Upload service</h1>
        <p class="sub-heading pb-2">An easy and efficient way to send us bathymetry data. </p>
        <admiralty-button aria-label="Request an account"
                          [type]="'button'"
                          class="request-account pr-4"
                          (click)="requestAccount()"
                          variant="primary">Request an account</admiralty-button>

        <admiralty-button id="logIn"
                          aria-label="Sign in"
                          class="sign-in"
                          [type]="'button'"
                          (click)="login()"
                          variant="secondary">Log in<admiralty-icon class="pl-4" iconName="arrow-right"></admiralty-icon>
        </admiralty-button>
      </section>
    </article>
  </div>
  <div class="tile is-12 pt-6">
    <article class="description">
      <section class="section">
        <p class="main-desc mt-6">Our UKHO Data Upload service is an easy-to-use online form that helps you submit bathymetry surveys quickly and accurately.</p>
        <h2 class="intro-title is-bold pt-5">Why use our data upload service?</h2>
        <p>The bathymetric data you share with the UKHO is very important. It helps to keep our seas safe.</p>

        <p>Our data upload service is:</p>
        <ul>
          <li>More accurate and consistent</li>
          <li>Time saving</li>
          <li>Easier to understand and navigate</li>
        </ul>

        <h2 class="intro-title is-bold pt-6">Need help?</h2>
        <p>For support with logging in or uploading data, contact
          <a href="mailto:customerservices@ukho.gov.uk"><strong>customerservices@ukho.gov.uk</strong></a>
        </p>

        <h3 class="desc-title pt-5">Share your feedback</h3>
        <p>Tell us about your experience using the UKHO Data Upload service through built-in feedback options or by emailing
          <a href="mailto:customerservices@ukho.gov.uk"><strong>customerservices@ukho.gov.uk</strong></a>
        </p>
      </section>
    </article>
  </div>
</div>
