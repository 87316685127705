export namespace Breadcrumbs {
  export type Item = 'Use a survey template' | 'Send a survey' | 'My surveys' | 'Contact us' | 'Surveys' | 'Suppliers';
  export const Item = {
    SurveyTemplates: 'Use a survey template' as Item,
    SendASurvey: 'Send a survey' as Item,
    MySurveys: 'My surveys' as Item,
    ContactUs: 'Contact us' as Item,
    Surveys: 'Surveys' as Item,
    Suppliers: 'Suppliers' as Item
  };
}
