import { BrowserCacheLocation, Configuration, LogLevel } from '@azure/msal-browser';
import { environment } from '../environments/environment';

export const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

// B2C Policy
export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1A_SignIn',
    userProfile: 'B2C_1A_UserProfile'
  },
  authorities: {
    signUpSignIn: {
      authority: `${environment.baseAuthorityDomain}/B2C_1A_SignIn`
    },
    userProfile: {
      authority: `${environment.baseAuthorityDomain}/B2C_1A_UserProfile`
    }
  },
  authorityDomain: `${environment.authorityDomain}`
};

export const apiConfig: { b2cScopes: string[]; webApi: string } = {
  b2cScopes: [
    `${environment.b2cScopeBaseUrl}/${environment.apiClientId}/EDUAPI.write`,
    `${environment.b2cScopeBaseUrl}/${environment.apiClientId}/EDUAPI.read`,
    `${environment.b2cScopeBaseUrl}/${environment.apiClientId}/ACGConnect.User.Authorisation`
  ],
  webApi: environment.dataUploadApiEndpoint
};

export const tokenRequest: { scopes: string[] } = {
  scopes: apiConfig.b2cScopes
};

export const loginRequest = {
  scopes: apiConfig.b2cScopes
};

// MSAL Configuration
export const msalConfig: Configuration = {
  auth: {
    clientId: environment.clientId, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: '/redirect', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    allowNativeBroker: false,
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {},
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false
    }
  }
};
