<div class="container mx-6 px-6">
  <article>
    <section class="section is-justify-content-left pb-1">
      <h1>This page cannot be found</h1>
    </section>
    <div class="tile mx-5 mb-6">
      <div class="tile is-12 is-justify-content-left">
        <article class="notification is-white is-full-width">
          <div>
            <p>It looks like the page you're looking for doesn't exist.</p>
            <p><a id="homePageLink" [routerLink]="['/external/dashboard']"><strong>Return to the homepage.</strong></a></p>
          </div>
        </article>
      </div>
    </div>
  </article>
</div>
