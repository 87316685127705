import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ElasticApmService } from '../services/elastic-apm.service';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class ErrorLoggingInterceptor implements HttpInterceptor {
  constructor(private elasticApmService: ElasticApmService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          this.elasticApmService.logError('intercepted-error', JSON.stringify(error), {
            method: req.method,
            url: req.url,
            status: error.status,
            params: req.params.toString(),
            body: JSON.stringify(req.body)
          });
        }

        if (error.status === 403 || error.status === 401) {
          this.router.navigate(['/permissions-error']);
        }

        return throwError(() => error);
      })
    );
  }
}
