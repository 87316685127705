import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { map, Observable, switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { HeaderItem } from './models/header-item.model';
import { NavigationService } from './services/navigation.service';
import { FooterItem } from './models/footer-item.model';
import { GtmService } from './services/gtm.service';
import { environment } from '../environments/environment';
import { BrowserStorageService } from './services/browser-storage.service';

@Component({
  selector: 'ukho-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public isAuthenticated$: Observable<boolean>;
  public isValidUser$: Observable<boolean>;

  public isUduUser$: Observable<boolean>;

  public getIsAuthedInternalUser$: Observable<boolean>;
  public getIsAuthedExternalUser$: Observable<boolean>;

  public fullname: Observable<string>;
  public header: any = {
    title: 'Data Upload',
    logoImgUrl: '../assets/UKHO_linear_logo.svg',
    logoLinkUrl: 'https://www.admiralty.co.uk/',
    logoAltText: 'UK Hydrographic Office',
    authOptions: {
      isSignedIn: () => this.isLoggedIn(),
      signInHandler: () => this.login(),
      signedInButtonText: 'Account',
      signOutHandler: (event) => this.logout(event),
      userProfileHandler: () => this.authenticationService.editProfile()
    }
  };

  public footerItems: FooterItem[] = [];
  public headerItems: HeaderItem[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private navigationService: NavigationService,
    private gtmService: GtmService,
    private browserStorageService: BrowserStorageService
  ) {
    this.browserStorageService.initSessionStorage();
    this.browserStorageService.initLocalStorage();
    this.footerItems = this.navigationService.getFooter();
    this.isAuthenticated$ = this.authenticationService.isAuthenticated$();
    this.isValidUser$ = this.authenticationService.getIsValidUser();
    this.getIsAuthedInternalUser$ = this.authenticationService.getIsAuthedInternalUser();
    this.getIsAuthedExternalUser$ = this.authenticationService.getIsAuthedExternalUduUser();
    this.isUduUser$ = this.authenticationService.getIsUduUser();
    this.fullname = this.authenticationService.getFullName();
  }

  ngOnInit(): void {
    if (environment.production) {
      this.gtmService.initializeGtm();
    }

    this.initializeBroadcast();
  }

  public initializeBroadcast(): void {
    this.authenticationService
      .initializeBroadcast()
      .pipe(
        switchMap(() => {
          return this.authenticationService.acquireTokenSilent();
        }),
        map(() => {
          this.authenticationService.setAccountDetails();
        })
      )
      .subscribe({
        next: () => {
          this.handleAuthNavigation();
        },
        error: () => {
          this.handleAuthNavigation();
        }
      });
  }

  public isLoggedIn(): boolean {
    return this.authenticationService.isAuthenticated();
  }

  public login(): void {
    localStorage.clear();
    this.authenticationService.login();
  }

  public logout(event: Event) {
    if (!this.authenticationService.getNavigateAllowed()) {
      event.preventDefault();
      const confirmed = confirm("If you leave this page, your survey won't be saved and can't be recovered.");

      if (confirmed) {
        this.authenticationService.logout();
      }
    } else {
      this.authenticationService.logout();
    }
  }

  public onAccountClicked(event: Event): void {
    if (!this.authenticationService.getNavigateAllowed()) {
      event.preventDefault();
      const confirmed = confirm("If you leave this page, your survey won't be saved and can't be recovered.");

      if (confirmed) {
        this.authenticationService.editProfile();
      }
    } else {
      this.authenticationService.editProfile();
    }
  }

  public sendEmail(): string {
    const recipient = 'products.feedback@UKHO.gov.uk';
    const subject = 'UKHO Data Upload';
    return `mailto:${recipient}?subject=${encodeURIComponent(subject)}`;
  }

  public navigate(routerLink: string) {
    this.browserStorageService.setSessionStorageData('form', null);
    this.browserStorageService.setSessionStorageData('formModified', null);
    this.browserStorageService.setSessionStorageData('surveyId', null);
    this.router.navigate([routerLink]);
  }

  public dashboardNavigate(): void {
    this.authenticationService
      .getIsAuthedInternalUser()
      .pipe(map((isInternal) => this.router.navigate([isInternal ? '/internal/dashboard' : '/external/dashboard'])))
      .subscribe();
  }

  public handleAuthNavigation(): void {
    const isInternalUser: boolean = this.authenticationService.getIsInternalUserValue();
    this.headerItems = isInternalUser
      ? this.navigationService.getInternalHeader(this.router)
      : this.navigationService.getExternalHeader(this.router);
  }
}
