import { AbstractControl, ValidatorFn } from '@angular/forms';

export function emailMatchValidator(controlName: string, matchingControlName: string): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: any } | null => {
    const emailControl = formGroup.get('email');
    const verifyEmailControl = formGroup.get('verifyEmail');

    if (!emailControl || !verifyEmailControl) {
      return null;
    }

    const email = emailControl.value;
    const verifiedEmail = verifyEmailControl.value;

    if (email !== verifiedEmail) {
      verifyEmailControl.setErrors({ emailMismatch: true });
    }

    return null;
  };
}
