import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { HeaderItem } from '../models/header-item.model';
import { Breadcrumbs } from '../enums/breadcrumbs.enum';
import { FooterItem } from '../models/footer-item.model';

@Injectable()
export class NavigationService {
  public convertToBool: { [key: string]: boolean } = {
    true: true,
    false: false
  };
  constructor() {}

  isEditingQueryParam(route: ActivatedRoute): Observable<boolean> {
    return route.queryParams.pipe(
      map((params) => {
        const key = params['isEditing'];
        if (key !== null && key !== undefined) {
          return this.convertToBool[key];
        } else {
          return false;
        }
      })
    );
  }

  isTemplateRouteQueryParam(route: ActivatedRoute): Observable<boolean> {
    return route.queryParams.pipe(
      map((params) => {
        const key = params['isTemplateRoute'];
        if (key !== null && key !== undefined) {
          return this.convertToBool[key];
        } else {
          return false;
        }
      })
    );
  }

  getInternalHeader(router: Router): HeaderItem[] {
    return [
      {
        title: Breadcrumbs.Item.Surveys,
        clickAction: () => router.navigate(['./internal/surveys']),
        navActive: false,
        routerLink: './internal/surveys'
      }
    ];
  }

  getExternalHeader(router: Router): HeaderItem[] {
    return [
      {
        title: Breadcrumbs.Item.SurveyTemplates,
        clickAction: () => router.navigate(['../external/survey-templates']),
        navActive: false,
        routerLink: '../external/survey-templates'
      },
      {
        title: Breadcrumbs.Item.SendASurvey,
        clickAction: () => router.navigate(['../external/survey-details']),
        navActive: false,
        routerLink: '../external/survey-details'
      },
      {
        title: Breadcrumbs.Item.MySurveys,
        clickAction: () => router.navigate(['./external/my-surveys']),
        navActive: false,
        routerLink: './external/my-surveys'
      },
      {
        title: Breadcrumbs.Item.ContactUs,
        clickAction: () => router.navigate(['./external/contact-us']),
        navActive: false,
        routerLink: './external/contact-us'
      }
    ];
  }

  getFooter(): FooterItem[] {
    return [
      {
        title: 'Privacy Policy',
        href: 'https://www.admiralty.co.uk/privacy-policy',
        newTab: true
      },
      {
        title: 'Accessibility',
        href: null,
        routerLink: './accessibility',
        newTab: false
      }
    ];
  }
}
