import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';

@Injectable()
export class ExternalUserAuthGuard {
  constructor(private authService: AuthenticationService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.authService.getIsAuthedExternalUduUser().pipe(
      map((isAuthedExternalUduUser: boolean) => {
        if (isAuthedExternalUduUser) {
          return true;
        } else {
          this.router.navigate([]);
          return false;
        }
      })
    );
  }
}
