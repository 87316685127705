import { ValidatorFn } from '@angular/forms';

export interface ValidationMatrix {
  fieldName: string;
  elementType: Element.type;
  validators?: ValidatorFn | ValidatorFn[];
  validationDetails: {
    requiredDetail?: ValidationDetail;
    maxLengthDetail?: ValidationDetail;
    patternDetail?: ValidationDetail;
    notUniqueDetail?: ValidationDetail;
    leadingWhitespaceDetail?: ValidationDetail;
    negativeDecimalDetail?: ValidationDetail;
    limitExceededDetail?: ValidationDetail;
    decimalLimitDetail?: ValidationDetail;
    invalidEmailDetail?: ValidationDetail;
    futureStartDateDetail?: ValidationDetail;
    futureEndDateDetail?: ValidationDetail;
    incorrectDateRangeDetail?: ValidationDetail;
    minDateValidatorDetail?: ValidationDetail;
    emailMismatchDetail?: ValidationDetail;
  };
}

export interface ValidationDetail {
  fieldMessage: string;
  bannerMessage: string;
  maxLength?: number;
  minLength?: number;
  pattern?: RegExp;
}

export namespace Element {
  export type type = 'Input' | 'Radio' | 'Dropdown' | 'Other';
  export const elementType = {
    Input: 'Input' as type,
    Radio: 'Radio' as type,
    Dropdown: 'Dropdown' as type,
    Other: 'Other' as type
  };
}
