import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class GtmService {
  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(PLATFORM_ID) private platformId: NonNullable<unknown>
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  initializeGtm() {
    if (isPlatformBrowser(this.platformId)) {
      const headScript = document.getElementById('gtm-head') as HTMLScriptElement;
      const bodyScript = document.getElementById('gtm-body') as HTMLElement;

      headScript.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-KBXZRD');`;

      const iframeElement = this.renderer.createElement('iframe');
      this.renderer.setAttribute(iframeElement, 'src', '');
      this.renderer.setAttribute(iframeElement, 'height', '0');
      this.renderer.setAttribute(iframeElement, 'width', '0');
      this.renderer.setStyle(iframeElement, 'display', 'none');
      this.renderer.setStyle(iframeElement, 'visibility', 'hidden');

      this.renderer.appendChild(bodyScript, iframeElement);
    }
  }
}
