import { Inject, Injectable, Optional } from '@angular/core';
import { environment } from '../../environments/environment';
import { ELASTIC_APM_TOKEN } from '../shared/elastic-apm.token';
import { ApmService } from '@elastic/apm-rum-angular';
import { AgentConfigOptions, ApmBase, Transaction } from '@elastic/apm-rum';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';

@Injectable()
export class ElasticApmService {
  protected elasticApmConfig: AgentConfigOptions | any = environment.elasticApmConfig;
  private apm: ApmBase;

  constructor(
    private apmService: ApmService,
    @Optional() @Inject(ELASTIC_APM_TOKEN) elasticApmConfig: AgentConfigOptions
  ) {
    if (elasticApmConfig) {
      this.elasticApmConfig = elasticApmConfig;
    }
  }

  public initialize(): void {
    this.apm = this.apmService.init({
      serviceName: this.elasticApmConfig.serviceName,
      serverUrl: this.elasticApmConfig.serverUrl,
      environment: this.elasticApmConfig.environment,
      logLevel: this.elasticApmConfig.logLevel,
      distributedTracingOrigins: this.elasticApmConfig.distributedTracingOrigins
    });
  }

  public setUserContext(id: string, username: string): void {
    this.apm.setUserContext({
      id: id,
      username: username
    });
  }

  public startTransaction(name: string, type = 'custom'): Transaction {
    return this.apm.startTransaction(name, type);
  }

  public endTransaction(transaction: Transaction): void {
    if (transaction.isFinished()) {
      transaction.end();
    }
  }

  public addContext(transaction: Transaction, purpose: string, page: string, labels: any): void {
    transaction.addLabels({
      purpose: purpose,
      page: page,
      metadata: JSON.stringify(labels)
    });
  }

  public addLabel(transaction: Transaction, flatObject: Labels): void {
    transaction.addLabels(flatObject);
  }

  public logValidationErrors(formName: string, formGroup: FormGroup): void {
    for (const field in formGroup.controls) {
      const control = formGroup.get(field);

      if (control instanceof FormControl) {
        this.handleFormControlError(control, formName, field);
      }

      if (control instanceof FormGroup) {
        for (const subField in (control as FormGroup).controls) {
          const subControl = (control as FormGroup).get(subField);
          this.handleFormControlError(subControl, formName, subField);
          this.handleFormControlError(control, formName, subField);
        }
      }

      if (control instanceof FormArray) {
        (control as FormArray).controls
          .map((group: FormGroup) => group)
          .forEach((formGroup) => {
            for (const subField in formGroup.controls) {
              const subControl = formGroup.get(subField);
              this.handleFormControlError(subControl, formName, subField);
            }
          });
      }
    }
  }

  public handleFormControlError(control: AbstractControl, formName: string, field: string): void {
    if (control.errors) {
      const errorInfo = {
        name: formName,
        message: `Validation failed for ${field}`,
        custom: JSON.stringify(control.errors)
      };

      this.apm.captureError(errorInfo);
    }
  }

  public logError(name: string, error: string, context = null): void {
    this.apm.captureError({
      name: name,
      message: error
    });

    if (context !== null && this.apm.getCurrentTransaction() !== undefined) {
      this.apm.getCurrentTransaction().addLabels(context);
    }
  }
}
