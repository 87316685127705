import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'ukho-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent {
  constructor(private router: Router, private authenticationService: AuthenticationService) {
    this.authenticationService.handleLoginNavigation(router).subscribe();
  }
}
