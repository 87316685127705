import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class BrowserStorageService {
  private storeName = 'appData';
  private sessionStorageSubject = new BehaviorSubject<any>(this.loadInitialData(true));
  private localStorageSubject = new BehaviorSubject<any>(this.loadInitialData(false));

  private loadInitialData(isSessionStorage: boolean): any {
    const store = JSON.parse(
      isSessionStorage ? sessionStorage.getItem(this.storeName || '{}') : localStorage.getItem(this.storeName || '{}')
    );
    return typeof store === 'object' ? store : {};
  }

  private ensureObject(data: any): any {
    return typeof data === 'object' && data !== null ? data : {};
  }

  public initSessionStorage(): void {
    const store = this.ensureObject(this.sessionStorageSubject.value);
    this.sessionStorageSubject.next(store);
    sessionStorage.setItem(this.storeName, JSON.stringify(store));
  }

  public initLocalStorage(): void {
    const store = this.ensureObject(this.localStorageSubject.value);
    this.localStorageSubject.next(store);
    localStorage.setItem(this.storeName, JSON.stringify(store));
  }

  public setSessionStorageData(category: string, data: any): void {
    const store = this.ensureObject(this.sessionStorageSubject.value);
    store[category] = data;
    this.sessionStorageSubject.next(store);
    sessionStorage.setItem(this.storeName, JSON.stringify(store));
  }

  public setLocalStorageData(category: string, data: any): void {
    const store = this.ensureObject(this.localStorageSubject.value);
    store[category] = data;
    this.localStorageSubject.next(store);
    localStorage.setItem(this.storeName, JSON.stringify(store));
  }

  public getItem(propertyValue: string, isSessionStorage = true): any {
    if (isSessionStorage) {
      return this.sessionStorageSubject.value[propertyValue];
    } else {
      return this.localStorageSubject.value[propertyValue];
    }
  }

  public itemChanges(): Observable<any> {
    return this.sessionStorageSubject.asObservable();
  }
}
