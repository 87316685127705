import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DesignSystemModule } from '@ukho/admiralty-angular';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular';
import { AuthenticationService } from './services/authentication.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication
} from '@azure/msal-browser';
import { apiConfig, isIE, loginRequest, msalConfig, tokenRequest } from './auth-config';
import { LandingComponent } from './containers/landing/landing.component';
import { CommonModule } from '@angular/common';
import { PreventDirectNavigationGuard } from './guards/direct-navigation.guard';
import { ContactUsComponent } from './containers/contact-us/contact-us.component';
import { NavigationService } from './services/navigation.service';
import { UnexpectedErrorComponent } from './shared/unexpected-error/unexpected-error.component';
import { InternalUserAuthGuard } from './guards/internal-user-auth.guard';
import { ExternalUserAuthGuard } from './guards/external-user-auth.guard';
import { GtmService } from './services/gtm.service';
import { UnderConstructionComponent } from './containers/under-construction/under-construction.component';
import { BrowserStorageService } from './services/browser-storage.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BASE_PATH } from './shared/data-upload.token';
import { environment } from '../environments/environment';
import { ApmErrorHandler, ApmModule, ApmService } from '@elastic/apm-rum-angular';
import { ELASTIC_APM_TOKEN } from './shared/elastic-apm.token';
import { ElasticApmService } from './services/elastic-apm.service';
import { RequestAccountComponent } from './containers/request-account/request-account.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RedirectComponent } from './containers/redirect/redirect.component';
import { ErrorLoggingInterceptor } from './interceptors/error-logging.interceptor';
import { AccessibilityComponent } from './containers/accessibility/accessibility.component';
import { NotFoundErrorComponent } from './shared/not-found-error/not-found-error.component';
import { PermissionsErrorComponent } from './shared/permissions-error/permissions-error.component';
import { ErrorComponent } from './shared/error/error.component';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    ContactUsComponent,
    UnexpectedErrorComponent,
    UnderConstructionComponent,
    RequestAccountComponent,
    RedirectComponent,
    AccessibilityComponent,
    NotFoundErrorComponent,
    PermissionsErrorComponent,
    ErrorComponent
  ],
  imports: [
    ApmModule,
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FontAwesomeModule,
    DesignSystemModule.forRoot(),
    MsalModule,
    MsalModule.forRoot(
      new PublicClientApplication(msalConfig),
      {
        interactionType: InteractionType.Redirect,
        authRequest: loginRequest
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([[apiConfig.webApi, [...tokenRequest.scopes]]])
      }
    ),
    FormsModule,
    ReactiveFormsModule,
    MatIconModule
  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.dataUploadApiEndpoint },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorLoggingInterceptor,
      multi: true
    },
    { provide: ELASTIC_APM_TOKEN, useValue: environment.elasticApmConfig },
    ElasticApmService,
    ApmService,
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler
    },
    AuthenticationService,
    MsalService,
    MsalGuard,
    InternalUserAuthGuard,
    ExternalUserAuthGuard,
    PreventDirectNavigationGuard,
    MsalBroadcastService,
    NavigationService,
    GtmService,
    BrowserStorageService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
  constructor(private elasticApmService: ElasticApmService) {
    this.elasticApmService.initialize();
  }
}
