import { ValidationMatrix, Element } from '../models/validation-matrix.model';
import { Validators } from '@angular/forms';
import { leadingWhitespaceValidator } from '../validators/leading-whitespace.validator';
import { decimalLimitValidator } from '../validators/decimal-limit.validator';

export class DataCollectionValidation {
  static numbersOnly = /^[0-9]*\.?[0-9]+$/;

  public static CollectingOrganisation: ValidationMatrix = {
    fieldName: 'Collecting Organisation',
    elementType: Element.elementType.Dropdown,
    validators: [Validators.required],
    validationDetails: {
      requiredDetail: {
        fieldMessage: 'Select the organisation that collected the survey data.',
        bannerMessage: 'Select the organisation that collected the survey data.'
      }
    }
  };

  public static SurveyPlatform: ValidationMatrix = {
    fieldName: 'Name of survey platform',
    elementType: Element.elementType.Input,
    validators: [Validators.required, Validators.maxLength(150), leadingWhitespaceValidator()],
    validationDetails: {
      requiredDetail: {
        fieldMessage: 'Enter the name of the survey platform.',
        bannerMessage: 'Enter the name of the survey platform.'
      },
      leadingWhitespaceDetail: {
        fieldMessage: 'Name of the survey platform cannot start with a blank space.',
        bannerMessage: 'Name of the survey platform cannot start with a blank space.'
      },
      maxLengthDetail: {
        fieldMessage: `Name of the survey platform must be 150 characters or less.`,
        bannerMessage: 'Name of the survey platform must be 150 characters or less.',
        maxLength: 150
      }
    }
  };

  public static InstrumentMakeAndModel: ValidationMatrix = {
    fieldName: 'Instrument make and model',
    elementType: Element.elementType.Input,
    validators: [Validators.required, Validators.maxLength(150), leadingWhitespaceValidator()],
    validationDetails: {
      requiredDetail: {
        fieldMessage: 'Enter the instrument make and model.',
        bannerMessage: 'Enter the instrument make and model.'
      },
      leadingWhitespaceDetail: {
        fieldMessage: 'Instrument make and model cannot start with a blank space.',
        bannerMessage: 'Instrument make and model cannot start with a blank space.'
      },
      maxLengthDetail: {
        fieldMessage: `Instrument make and model must be 150 characters or less.`,
        bannerMessage: 'Instrument make and model must be 150 characters or less.',
        maxLength: 150
      }
    }
  };

  public static PositioningSystem: ValidationMatrix = {
    fieldName: 'Positioning System',
    elementType: Element.elementType.Input,
    validators: [Validators.required, Validators.maxLength(200), leadingWhitespaceValidator()],
    validationDetails: {
      requiredDetail: {
        fieldMessage: 'Enter the positioning system.',
        bannerMessage: 'Enter the positioning system.'
      },
      leadingWhitespaceDetail: {
        fieldMessage: 'Positioning system cannot start with a blank space.',
        bannerMessage: 'Positioning system cannot start with a blank space.'
      },
      maxLengthDetail: {
        fieldMessage: `Positioning system must be 200 characters or less.`,
        bannerMessage: 'Positioning system must be 200 characters or less. ',
        maxLength: 200
      }
    }
  };

  public static MethodOfSurvey: ValidationMatrix = {
    fieldName: 'Method of survey',
    elementType: Element.elementType.Dropdown,
    validators: [Validators.required, Validators.maxLength(100)],
    validationDetails: {
      requiredDetail: {
        fieldMessage: 'Select the method of survey.',
        bannerMessage: 'Select the method of survey.'
      }
    }
  };

  public static GriddedResolution: ValidationMatrix = {
    fieldName: 'Gridded resolution',
    elementType: Element.elementType.Input,
    validators: [
      Validators.required,
      Validators.pattern(this.numbersOnly),
      decimalLimitValidator(8, 2),
      Validators.maxLength(11),
      leadingWhitespaceValidator()
    ],
    validationDetails: {
      maxLengthDetail: {
        fieldMessage: `Gridded resolution must be 8 digits or less.`,
        bannerMessage: 'Gridded resolution must be 8 digits or less.'
      },
      requiredDetail: {
        fieldMessage: 'Enter the gridded resolution.',
        bannerMessage: 'Enter the gridded resolution.'
      },
      decimalLimitDetail: {
        fieldMessage: 'Gridded resolution must have 2 decimal points or less.',
        bannerMessage: 'Gridded resolution must have 2 decimal points or less.'
      },
      limitExceededDetail: {
        fieldMessage: `Gridded resolution must be 8 digits or less.`,
        bannerMessage: 'Gridded resolution must be 8 digits or less.'
      },
      patternDetail: {
        fieldMessage: `Gridded resolution must be 8 digits or less.`,
        bannerMessage: 'Gridded resolution must be 8 digits or less.'
      }
    }
  };

  public static GriddedDataSupplied: ValidationMatrix = {
    fieldName: 'Gridded data supplied',
    elementType: Element.elementType.Radio,
    validators: [Validators.required],
    validationDetails: {
      requiredDetail: {
        fieldMessage: 'Confirm if you are supplying thinned or gridded data.',
        bannerMessage: 'Confirm if you are supplying thinned or gridded data.'
      }
    }
  };

  public static MethodUsed: ValidationMatrix = {
    fieldName: 'Method used',
    elementType: Element.elementType.Input,
    validators: [Validators.required, Validators.maxLength(200), leadingWhitespaceValidator()],
    validationDetails: {
      requiredDetail: {
        fieldMessage: 'Select the gridding method used.',
        bannerMessage: 'Select the gridding method used.'
      },
      leadingWhitespaceDetail: {
        fieldMessage: 'Method used cannot start with a blank space.',
        bannerMessage: 'Method used cannot start with a blank space.'
      },
      maxLengthDetail: {
        fieldMessage: `Method used must be 200 characters or less.`,
        bannerMessage: 'Method used must be 200 characters or less.',
        maxLength: 200
      }
    }
  };

  public static MethodUsedOther: ValidationMatrix = {
    fieldName: 'Method used other',
    elementType: Element.elementType.Input,
    validators: [Validators.required, Validators.maxLength(400), leadingWhitespaceValidator()],
    validationDetails: {
      requiredDetail: {
        fieldMessage: 'Enter more information about gridding method used when selecting Other.',
        bannerMessage: 'Enter more information about gridding method used.'
      },
      leadingWhitespaceDetail: {
        fieldMessage: 'Information about gridding method used cannot start with a blank space.',
        bannerMessage: 'Information about gridding method used cannot start with a blank space.'
      },
      maxLengthDetail: {
        fieldMessage: `Information about gridding method used must be 400 characters or less.`,
        bannerMessage: 'Information about gridding method used must be 400 characters or less.',
        maxLength: 400
      }
    }
  };

  public static DataInterpolated: ValidationMatrix = {
    fieldName: 'Data Interpolated',
    elementType: Element.elementType.Radio,
    validators: [Validators.required],
    validationDetails: {
      requiredDetail: {
        fieldMessage: 'Confirm if the data has been interpolated.',
        bannerMessage: 'Confirm if the data has been interpolated.'
      }
    }
  };

  public static DataInterpolatedOther: ValidationMatrix = {
    fieldName: 'Data Interpolated Other',
    elementType: Element.elementType.Input,
    validators: [Validators.maxLength(400), leadingWhitespaceValidator()],
    validationDetails: {
      requiredDetail: {
        fieldMessage: 'Enter more information about interpolated data when selecting Yes.',
        bannerMessage: 'Enter more information about interpolated data.'
      },
      leadingWhitespaceDetail: {
        fieldMessage: 'Information about interpolated cannot start with a blank space.',
        bannerMessage: 'Information about interpolated cannot start with a blank space.'
      },
      maxLengthDetail: {
        fieldMessage: `Information about interpolated data must be 400 characters or less.`,
        bannerMessage: 'Information about interpolated data must be 400 characters or less.',
        maxLength: 400
      }
    }
  };

  public static CoordinateType: ValidationMatrix = {
    fieldName: 'Coordinate Type',
    elementType: Element.elementType.Radio,
    validators: [Validators.required],
    validationDetails: {
      requiredDetail: {
        fieldMessage: 'Select the coordinate type.',
        bannerMessage: 'Select the coordinate type.'
      }
    }
  };

  public static HorizontalDatum: ValidationMatrix = {
    fieldName: 'Horizontal Datum',
    elementType: Element.elementType.Dropdown,
    validators: [Validators.required],
    validationDetails: {
      requiredDetail: {
        fieldMessage: 'Select the horizontal datum.',
        bannerMessage: 'Select the horizontal datum.'
      }
    }
  };

  public static HorizontalDatumOther: ValidationMatrix = {
    fieldName: 'Horizontal Datum Other',
    elementType: Element.elementType.Input,
    validators: [Validators.maxLength(100), leadingWhitespaceValidator()],
    validationDetails: {
      requiredDetail: {
        fieldMessage: 'Enter more information about the horizontal datum when selecting Other.',
        bannerMessage: 'Enter more information about the horizontal datum.'
      },
      leadingWhitespaceDetail: {
        fieldMessage: 'Information about horizontal datum cannot start with a blank space.',
        bannerMessage: 'Information about horizontal datum cannot start with a blank space.'
      },
      maxLengthDetail: {
        fieldMessage: `Information about the horizontal datum must be 100 characters or less.`,
        bannerMessage: 'Information about the horizontal datum must be 100 characters or less.',
        maxLength: 100
      }
    }
  };

  public static VerticalDatum: ValidationMatrix = {
    fieldName: 'Vertical Datum',
    elementType: Element.elementType.Dropdown,
    validators: [Validators.required],
    validationDetails: {
      requiredDetail: {
        fieldMessage: 'Select the vertical datum.',
        bannerMessage: 'Select the vertical datum.'
      }
    }
  };

  public static VerticalDatumOther: ValidationMatrix = {
    fieldName: 'Vertical Datum Other',
    elementType: Element.elementType.Input,
    validators: [Validators.maxLength(100), leadingWhitespaceValidator()],
    validationDetails: {
      requiredDetail: {
        fieldMessage: 'Enter more information about the vertical datum when selecting Other or Local.',
        bannerMessage: 'Enter more information about the vertical datum.'
      },
      leadingWhitespaceDetail: {
        fieldMessage: 'Information about vertical datum cannot start with a blank space.',
        bannerMessage: 'Information about vertical datum cannot start with a blank space.'
      },
      maxLengthDetail: {
        fieldMessage: `Information about the vertical datum must be 100 characters or less.`,
        bannerMessage: 'Information about the vertical datum must be 100 characters or less.',
        maxLength: 100
      }
    }
  };

  public static Projection: ValidationMatrix = {
    fieldName: 'Projection',
    elementType: Element.elementType.Input,
    validators: [Validators.maxLength(1024), leadingWhitespaceValidator()],
    validationDetails: {
      leadingWhitespaceDetail: {
        fieldMessage: 'Information about projection cannot start with a blank space.',
        bannerMessage: 'Information about projection cannot start with a blank space.'
      },
      maxLengthDetail: {
        fieldMessage: `Information about projection data must be 1,024 characters or less.`,
        bannerMessage: 'Information about projection must be 1,024 characters or less.',
        maxLength: 1024
      }
    }
  };
}
