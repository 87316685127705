import { Component } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'ukho-permissions-error',
  templateUrl: './permissions-error.component.html',
  styleUrls: ['./permissions-error.component.scss']
})
export class PermissionsErrorComponent {
  constructor(private authenticationService: AuthenticationService) {}

  public login(): void {
    this.authenticationService.login();
  }
}
