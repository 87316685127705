<div class="container mx-6 px-6">
  <article>
    <section class="section is-justify-content-left pb-1">
      <h1>An error has occurred</h1>
    </section>
    <div class="tile mx-5 mb-6">
      <div class="tile is-12 is-justify-content-left">
        <article class="notification is-white is-full-width">
          <div>
            <p>We apologise for the inconvenience, but an error has occurred.</p>
            <p>Please <a id="homePageLink" [routerLink]="['/external/dashboard']"><strong>return to the homepage</strong></a> and try again. if the issue persists, please contact <br>Customer Services for further assistance.</p>
            <p>Email: <a [href]="'mailto:customerservices@ukho.gov.uk?subject=UKHO%20Data%20Upload%20Permissions%20Issue'"><strong>customerservices@ukho.gov.uk</strong></a><br>We aim to respond within two working days.</p>
          </div>
        </article>
      </div>
    </div>
  </article>
</div>
