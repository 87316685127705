import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { DataCollectionValidation } from '../../page-validations/data-collection.validation';

@Component({
  selector: 'ukho-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent {
  public isAuthenticated: Observable<boolean>;
  public faArrowRight = faArrowRight;

  constructor(private router: Router, private authenticationService: AuthenticationService) {
    this.authenticationService.handleLoginNavigation(router).subscribe();
  }

  public login(): void {
    localStorage.clear();
    this.authenticationService.login();
  }

  public requestAccount(): void {
    this.router.navigate(['/request-an-account']);
  }

  protected readonly DataCollectionValidation = DataCollectionValidation;
}
