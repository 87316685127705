import { Element, ValidationMatrix } from '../models/validation-matrix.model';
import { Validators } from '@angular/forms';
import { leadingWhitespaceValidator } from '../validators/leading-whitespace.validator';
import { emailValidator } from '../validators/email.validator';

export class RequestAccountValidation {
  public static OrganisationName: ValidationMatrix = {
    fieldName: 'Organisation name',
    elementType: Element.elementType.Input,
    validators: [Validators.required, Validators.maxLength(160), leadingWhitespaceValidator()],
    validationDetails: {
      requiredDetail: {
        fieldMessage: 'Enter your organisation name.',
        bannerMessage: 'Enter your organisation name.'
      },
      maxLengthDetail: {
        fieldMessage: 'Organisation name must be 160 characters or less.',
        bannerMessage: 'Organisation name must be 160 characters or less.',
        maxLength: 160
      },
      leadingWhitespaceDetail: {
        fieldMessage: 'Organisation name cannot start with a blank space.',
        bannerMessage: 'Organisation name cannot start with a blank space.'
      }
    }
  };

  public static WebsiteUrl: ValidationMatrix = {
    fieldName: 'Website Url',
    elementType: Element.elementType.Input,
    validators: [Validators.maxLength(160)],
    validationDetails: {
      maxLengthDetail: {
        fieldMessage: 'Website URL must be 160 characters or less.',
        bannerMessage: 'Website URL must be 160 characters or less.',
        maxLength: 160
      }
    }
  };

  public static FirstName: ValidationMatrix = {
    fieldName: 'First name',
    elementType: Element.elementType.Input,
    validators: [Validators.required, Validators.maxLength(160), leadingWhitespaceValidator()],
    validationDetails: {
      requiredDetail: {
        fieldMessage: 'Enter your first name.',
        bannerMessage: 'Enter your first name.'
      },
      maxLengthDetail: {
        fieldMessage: 'First name must be 160 characters or less.',
        bannerMessage: 'First name must be 160 characters or less.',
        maxLength: 160
      },
      leadingWhitespaceDetail: {
        fieldMessage: 'First name cannot start with a blank space.',
        bannerMessage: 'First name cannot start with a blank space.'
      }
    }
  };

  public static LastName: ValidationMatrix = {
    fieldName: 'Last name',
    elementType: Element.elementType.Input,
    validators: [Validators.required, Validators.maxLength(160), leadingWhitespaceValidator()],
    validationDetails: {
      requiredDetail: {
        fieldMessage: 'Enter your last name.',
        bannerMessage: 'Enter your last name.'
      },
      maxLengthDetail: {
        fieldMessage: 'Last name must be 160 characters or less.',
        bannerMessage: 'Last name must be 160 characters or less.',
        maxLength: 160
      },
      leadingWhitespaceDetail: {
        fieldMessage: 'Last name cannot start with a blank space.',
        bannerMessage: 'Last name cannot start with a blank space.'
      }
    }
  };

  public static JobTitle: ValidationMatrix = {
    fieldName: 'Job Title',
    elementType: Element.elementType.Input,
    validators: [Validators.required, Validators.maxLength(160), leadingWhitespaceValidator()],
    validationDetails: {
      requiredDetail: {
        fieldMessage: 'Enter your job title.',
        bannerMessage: 'Enter your job title.'
      },
      maxLengthDetail: {
        fieldMessage: 'Job title must be 160 characters or less.',
        bannerMessage: 'Job title must be 160 characters or less.',
        maxLength: 160
      },
      leadingWhitespaceDetail: {
        fieldMessage: 'Job title cannot start with a blank space.',
        bannerMessage: 'Job title cannot start with a blank space.'
      }
    }
  };

  public static EmailAddress: ValidationMatrix = {
    fieldName: 'Email',
    elementType: Element.elementType.Input,
    validators: [Validators.required, Validators.maxLength(100), leadingWhitespaceValidator(), emailValidator()],
    validationDetails: {
      requiredDetail: {
        fieldMessage: 'Enter your email address.',
        bannerMessage: 'Enter your email address.'
      },
      maxLengthDetail: {
        fieldMessage: 'Your email address must be 100 characters or less.',
        bannerMessage: 'Your email address must be 100 characters or less.',
        maxLength: 100
      },
      leadingWhitespaceDetail: {
        fieldMessage: 'Email address cannot start with a blank space.',
        bannerMessage: 'Email address cannot start with a blank space.'
      },
      invalidEmailDetail: {
        fieldMessage: 'Email address must be entered in a name@example.com format.',
        bannerMessage: 'Email address must be entered in a name@example.com format.'
      },
      emailMismatchDetail: {
        fieldMessage: 'Email addresses do not match.',
        bannerMessage: 'Email addresses do not match'
      }
    }
  };

  public static VerifyEmailAddress: ValidationMatrix = {
    fieldName: 'Verify email',
    elementType: Element.elementType.Input,
    validators: [Validators.required, Validators.maxLength(100), leadingWhitespaceValidator(), emailValidator()],
    validationDetails: {
      requiredDetail: {
        fieldMessage: 'Enter your email address.',
        bannerMessage: 'Enter your email address.'
      },
      maxLengthDetail: {
        fieldMessage: 'Your email address must be 100 characters or less.',
        bannerMessage: 'Your email address must be 100 characters or less.',
        maxLength: 100
      },
      leadingWhitespaceDetail: {
        fieldMessage: 'Verify email address cannot start with a blank space.',
        bannerMessage: 'Verify email address cannot start with a blank space.'
      },
      invalidEmailDetail: {
        fieldMessage: 'Verify email address must be entered in a name@example.com format.',
        bannerMessage: 'Verify email address must be entered in a name@example.com format.'
      },
      emailMismatchDetail: {
        fieldMessage: 'The email address and confirm email address fields must be the same.',
        bannerMessage: 'The email address and confirm email address fields must be the same.'
      }
    }
  };
}
