import { Inject, Injectable, Optional } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  filter,
  from,
  map,
  Observable,
  ObservableInput,
  ReplaySubject,
  Subject,
  takeUntil
} from 'rxjs';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EventType,
  InteractionRequiredAuthErrorCodes,
  InteractionStatus,
  RedirectRequest
} from '@azure/msal-browser';
import { apiConfig, b2cPolicies } from '../auth-config';
import { BASE_PATH } from '../shared/data-upload.token';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ElasticApmService } from './elastic-apm.service';
import { Roles } from '../enums/roles.enum';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationService {
  protected basePath = environment.dataUploadApiEndpoint;

  private ukhoRegex = /(?<=@)ukho\.gov\.uk/i;
  private readonly destroying$ = new Subject<void>();

  private readonly accountDetails: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);
  private readonly objId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private readonly idToken: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private readonly fullName: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>('');
  private readonly givenName: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>('');
  private readonly email: BehaviorSubject<string | unknown> = new BehaviorSubject<string | unknown>('');

  private readonly roles: BehaviorSubject<string[] | unknown> = new BehaviorSubject<string[]>([]);
  private readonly isUduUser: ReplaySubject<boolean> = new ReplaySubject<boolean>(null);
  private readonly isFtpUser: ReplaySubject<boolean> = new ReplaySubject<boolean>(null);
  private readonly userRoles: ReplaySubject<string[]> = new ReplaySubject<string[]>(null);
  private readonly isInternalUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  private readonly accessToken: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private readonly isAuthed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly navigateAllowed = new BehaviorSubject<boolean>(true);

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Optional() @Inject(BASE_PATH) basePath: string,
    private httpClient: HttpClient,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private elasticApmService: ElasticApmService
  ) {
    if (basePath) {
      this.basePath = basePath;
    }

    this.basePath = basePath + '/api/external';

    this.msalBroadcastService.msalSubject$.subscribe((event) => {
      if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        this.setAccessToken(event.payload['accessToken']);
      }
    });

    this.checkIsAuthenticated();
  }

  public initializeBroadcast(): Observable<string> {
    return this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this.destroying$)
    );
  }

  public getUserRoles(): Observable<string[] | any> {
    return this.httpClient.request<string[]>('get', `${this.basePath}/user/get-roles`).pipe(
      map((roles: string[]) => {
        this.setUserRoles(roles);
        this.setIsUduUser(roles.includes(Roles.Role.User));
        this.setIsFtpUser(roles.includes(Roles.Role.Ftp));
        return roles;
      }),
      catchError((error: HttpErrorResponse): ObservableInput<Error> => {
        this.checkIsAuthenticated();
        if (error.status === 403 || error.status === 401) {
          this.setUserRoles([]);
          this.setIsUduUser(false);
          return new Observable((observer) => observer.error(new Error('The user is not assigned the role')));
        } else {
          this.setUserRoles([]);
          return new Observable((observer) => observer.error(error));
        }
      })
    );
  }

  public login(userFlowRequest?: RedirectRequest) {
    if (this.msalGuardConfig.authRequest) {
      this.msalService
        .loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest)
        .subscribe();
    } else {
      this.msalService.loginRedirect(userFlowRequest).subscribe();
    }
  }

  public getIsAuthedExternalUduUser(): Observable<boolean> {
    return combineLatest([
      this.isAuthed.asObservable(),
      this.isUduUser.asObservable(),
      this.isInternalUser.asObservable()
    ]).pipe(
      map(
        ([isAuthed, isUduUser, isInternalUser]: [boolean, boolean, boolean]) => isAuthed && isUduUser && !isInternalUser
      )
    );
  }

  public getIsAuthedInternalUser(): Observable<boolean> {
    return combineLatest([this.isAuthed.asObservable(), this.isInternalUser.asObservable()]).pipe(
      map(([isAuthed, isInternalUser]: [boolean, boolean]) => isAuthed && isInternalUser)
    );
  }

  public getIsValidUser(): Observable<boolean> {
    return combineLatest([this.getIsAuthedExternalUduUser(), this.getIsAuthedInternalUser()]).pipe(
      map(
        ([isAuthedExternalUduUser, isAuthedInternalUser]: [boolean, boolean]) =>
          isAuthedExternalUduUser || isAuthedInternalUser
      )
    );
  }

  public checkIsAuthenticated(): void {
    return this.isAuthed.next(this.msalService.instance.getAllAccounts().length > 0);
  }

  public getIsUduUser(): Observable<boolean> {
    return this.isUduUser.asObservable();
  }

  public isAuthenticated$(): Observable<boolean> {
    return this.isAuthed.asObservable();
  }

  public isAuthenticated(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  public hasUduRole(): Observable<boolean> {
    return this.isUduUser.asObservable();
  }

  public setUserRoles(roles: string[]): void {
    return this.userRoles.next(roles);
  }

  public setIsUduUser(value: boolean): void {
    return this.isUduUser.next(value);
  }

  public setIsFtpUser(value: boolean): void {
    return this.isFtpUser.next(value);
  }

  public logout() {
    this.msalService.logout();
    this.resetAuthDetails();
  }

  public setNavigateAllowed(canNavigate: boolean) {
    this.navigateAllowed.next(canNavigate);
  }

  public getSignOutAllowedObservable(): Observable<boolean> {
    return this.navigateAllowed.asObservable();
  }

  public getNavigateAllowed(): boolean {
    return this.navigateAllowed.value;
  }

  public getAccountDetails(): Observable<AccountInfo | null> {
    return this.accountDetails.asObservable();
  }

  public setAccountDetails() {
    const accountDetails = this.msalService.instance.getAllAccounts()[0];
    if (accountDetails !== null && accountDetails !== undefined) {
      this.msalService.instance.setActiveAccount(accountDetails);
      const email = accountDetails.idTokenClaims.email;

      if (email !== null && email !== undefined && typeof email === 'string') {
        this.isInternalUser.next(this.ukhoRegex.test(email));
      }

      this.roles.next(accountDetails.idTokenClaims.role);
      this.accountDetails.next(accountDetails);
      this.fullName.next(accountDetails?.name.replace(',', ''));
      this.givenName.next(accountDetails?.idTokenClaims?.given_name.toString());
    }
  }

  public editProfile() {
    const editProfileFlowRequest = {
      scopes: ['openid'],
      authority: b2cPolicies.authorities.userProfile.authority
    };

    this.login(editProfileFlowRequest);
  }

  public acquireTokenSilent(): Observable<unknown> {
    return from(
      this.msalService.instance
        .acquireTokenSilent({ account: this.msalService.instance.getAllAccounts()[0], scopes: apiConfig.b2cScopes })
        .then((authResult: AuthenticationResult) => {
          this.checkIsAuthenticated();
          this.setAccessToken(authResult.accessToken);

          const isExternalEmail = !this.ukhoRegex.test((authResult.idTokenClaims as any).email);
          isExternalEmail ? this.getUserRoles().subscribe() : this.setIsUduUser(false);

          this.elasticApmService.setUserContext(authResult.uniqueId, authResult.account.username);
          return authResult;
        })
        .catch((e) => {
          if (e.errorCode === InteractionRequiredAuthErrorCodes.interactionRequired) {
            this.elasticApmService.logError(e.name, `${e.errorCode}: ${e.errorMessage}`);
            this.login();
          } else if (e.errorCode !== 'no_account_error') {
            this.elasticApmService.logError('acquireTokenSilent', JSON.stringify(e));
          }

          this.checkIsAuthenticated();
          return e;
        })
    ).pipe(
      map((authResponse) => {
        if (authResponse.account !== undefined && authResponse.account.idTokenClaims !== undefined) {
          const email = authResponse.account.idTokenClaims?.email;

          if (email !== null && email !== undefined && typeof email === 'string') {
            this.isInternalUser.next(this.ukhoRegex.test(email));
          }

          this.email.next(email);
          this.objId.next(authResponse.uniqueId);
          this.idToken.next(authResponse.idToken);

          this.roles.next(authResponse.account.idTokenClaims?.role);
        } else {
          console.warn('Auth account empty', authResponse);
        }
      }),
      catchError((error: HttpErrorResponse): ObservableInput<Error> => {
        if (error.status === 403 || error.status === 401) {
          this.setIsUduUser(false);
          return new Observable((observer) => observer.error(new Error('The user is not assigned the role')));
        } else {
          return new Observable((observer) => observer.error(error));
        }
      })
    );
  }

  public handleLoginNavigation(router: Router): Observable<void> {
    return combineLatest([this.isAuthenticated$(), this.getIsInternalUser(), this.hasUduRole()]).pipe(
      map(([isAuthed, isInternalUser, hasUduRole]: [boolean, boolean, boolean]) => {
        if (isAuthed) {
          if (isInternalUser) {
            router.navigate(['/internal/dashboard']);
          } else {
            if (hasUduRole !== null && isInternalUser === false) {
              void (hasUduRole ? router.navigate(['/external/dashboard']) : router.navigate(['/permissions-error']));
            }
          }
        } else {
          void router.navigate([]);
        }
      })
    );
  }

  public getFullName(): Observable<string | undefined> {
    return this.fullName.asObservable();
  }

  public getFullNameValue(): string | undefined {
    return this.fullName.value;
  }

  public getGivenName(): Observable<string | undefined> {
    return this.givenName.asObservable();
  }

  public getEmail(): Observable<string | unknown> {
    return this.email.asObservable();
  }

  public getEmailValue(): string | unknown {
    return this.email.value;
  }

  public getIsInternalUser(): Observable<boolean> {
    return this.isInternalUser.asObservable();
  }

  public getIsFtpUser(): Observable<boolean> {
    return this.isFtpUser.asObservable();
  }

  public getIsInternalUserValue(): boolean {
    return this.isInternalUser.value;
  }

  public getObjectId(): Observable<string | undefined> {
    return this.objId.asObservable();
  }

  public getIdToken(): Observable<string | undefined> {
    return this.idToken.asObservable();
  }

  public getAccessToken(): string {
    return this.accessToken.value;
  }

  public setAccessToken(accessToken: string): void {
    this.accessToken.next(accessToken);
  }

  private resetAuthDetails() {
    this.fullName.next('');
    this.email.next('');
    this.objId.next('');
    this.idToken.next('');
    this.accountDetails.next(null);
  }
}
