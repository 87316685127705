import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const emailRegex = /^(?!.*?[.]{2})[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@([^.@][^@\s]*\.[^.@][^@\s]+)$/;

    if (control.value !== null && control.value !== undefined && !control.value.toString().startsWith(' ')) {
      const result = emailRegex.test(control.value);
      return result ? null : { invalidEmail: true };
    }

    return null;
  };
}
