<div [formGroup]="form" class="container pt-6 my-6">
  <div class="columns is-centered main-tile">
    <div *ngIf="!showSuccessPage" class="column is-5">
      <ng-container *ngIf="this.form.invalid && this.form.touched && emailClicked">
        <admiralty-dialogue id="error-summary" type="error" heading="There's a problem">
          <p class="mb-3" *ngIf="this.form.controls.organisationName.invalid">{{ validateMessage(this.form.controls.organisationName, RequestAccountValidation.OrganisationName, false) }}</p>
          <p class="mb-3" *ngIf="this.form.controls.website.invalid">{{ validateMessage(this.form.controls.website, RequestAccountValidation.WebsiteUrl, false) }}</p>
          <p class="mb-3" *ngIf="this.form.controls.firstName.invalid">{{ validateMessage(this.form.controls.firstName, RequestAccountValidation.FirstName, false) }}</p>
          <p class="mb-3" *ngIf="this.form.controls.lastName.invalid">{{ validateMessage(this.form.controls.lastName, RequestAccountValidation.LastName, false) }}</p>
          <p class="mb-3" *ngIf="this.form.controls.jobTitle.invalid">{{ validateMessage(this.form.controls.jobTitle, RequestAccountValidation.JobTitle, false) }}</p>
          <p class="mb-3" *ngIf="this.form.controls.emailDetails.controls.email.invalid">{{ validateMessage(this.form.controls.emailDetails.controls.email, RequestAccountValidation.EmailAddress, false) }}</p>
          <p class="mb-3" *ngIf="this.form.controls.emailDetails.controls.verifyEmail.invalid">{{ validateMessage(this.form.controls.emailDetails.controls.verifyEmail, RequestAccountValidation.VerifyEmailAddress, false) }}</p>
        </admiralty-dialogue>
      </ng-container>

      <h1 class="pt-6">Request an account</h1>
      <p>Complete this form and select <b>Create email request</b> to open an email pre filled with your account request.</p>
      <p>You'll need to select <b>Send</b> within the email to finishing sending your request.</p>

      <h5 class="pt-4 pb-5">Organisation details</h5>
      <admiralty-input id="organisation-name"
                       formControlName="organisationName"
                       [required]="true"
                       [invalid]="this.form.controls.organisationName.invalid && this.form.controls.organisationName.touched"
                       [invalidMessage]="validateMessage(this.form.controls.organisationName, RequestAccountValidation.OrganisationName, true)"
                       type="text"
                       label="Organisation name"
                       name="Organisation name"></admiralty-input>

      <admiralty-input id="website"
                       formControlName="website"
                       [invalid]="this.form.controls.website.invalid && this.form.controls.website.touched"
                       [invalidMessage]="validateMessage(this.form.controls.website, RequestAccountValidation.WebsiteUrl, true)"
                       type="text"
                       label="Website URL (optional)"
                       name="Website URL (optional)"></admiralty-input>

      <h5 class="pt-6 pb-5">Personal details</h5>
      <admiralty-input id="first-name"
                       formControlName="firstName"
                       [required]="true"
                       [invalid]="this.form.controls.firstName.invalid && this.form.controls.firstName.touched"
                       [invalidMessage]="validateMessage(this.form.controls.firstName, RequestAccountValidation.FirstName, true)"
                       type="text"
                       label="First name"
                       name="First name"></admiralty-input>

      <admiralty-input id="last-name"
                       formControlName="lastName"
                       [required]="true"
                       [invalid]="this.form.controls.lastName.invalid && this.form.controls.lastName.touched"
                       [invalidMessage]="validateMessage(this.form.controls.lastName, RequestAccountValidation.LastName, true)"
                       type="text"
                       label="Last name"
                       name="Last name"></admiralty-input>

      <admiralty-input id="job-title"
                       formControlName="jobTitle"
                       [required]="true"
                       [invalid]="this.form.controls.jobTitle.invalid && this.form.controls.jobTitle.touched"
                       [invalidMessage]="validateMessage(this.form.controls.jobTitle, RequestAccountValidation.JobTitle, true)"
                       type="text"
                       label="Job title"
                       name="Job title"></admiralty-input>

      <div formGroupName="emailDetails" class="pb-5">
        <admiralty-input id="email"
                         formControlName="email"
                         [required]="true"
                         [invalid]="this.form.controls.emailDetails.controls.email.invalid && this.form.controls.emailDetails.controls.email.touched"
                         [invalidMessage]="validateMessage(this.form.controls.emailDetails.controls.email, RequestAccountValidation.EmailAddress, true)"
                         type="text"
                         label="Email address"
                         name="Email"></admiralty-input>

        <admiralty-input id="verify-email"
                         formControlName="verifyEmail"
                         [required]="true"
                         [invalid]="this.form.controls.emailDetails.controls.verifyEmail.invalid && this.form.controls.emailDetails.controls.verifyEmail.touched"
                         [invalidMessage]="validateMessage(this.form.controls.emailDetails.controls.verifyEmail, RequestAccountValidation.VerifyEmailAddress, true)"
                         (paste)="handleOnPaste()"
                         type="text"
                         label="Confirm email address"
                         name="Verify email"></admiralty-input>
      </div>
      <admiralty-button id="create-request"
                        class="create-request"
                        (click)="sendRequest()"
                        variant="primary">Create email request</admiralty-button>

      <div class="has-text-centered py-5">
        <p>You can opt out at any time. By selecting Send request you agree to our <admiralty-link href="https://www.admiralty.co.uk/terms-and-conditions" [newTab]="true"><b>Terms & Conditions</b></admiralty-link> and <admiralty-link href="https://www.admiralty.co.uk/privacy-policy" [newTab]="true"><b>Privacy Policy</b></admiralty-link></p>
      </div>
    </div>
    <div *ngIf="showSuccessPage" class="column is-5 has-text-centered mb-6">
      <mat-icon class="success-icon" fontIcon="task_alt"></mat-icon>
      <h1>Finish request</h1>

      <div class="has-text-centered py-2">
        <p>If you haven't already, you must select <b>Send</b> within the pre filled email to finish requesting your account.</p>
        <p>Your email will be received by our customer services team, and they will contact you if they have any questions.</p>
      </div>

      <admiralty-button id="return"
                        class="return"
                        [routerLink]="['']"
                        variant="primary">Return to login page</admiralty-button>
    </div>
  </div>
</div>
