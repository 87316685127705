import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';

@Injectable()
export class InternalUserAuthGuard {
  constructor(private authService: AuthenticationService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.authService.getIsAuthedInternalUser().pipe(
      map((isAuthedInternalUser: boolean) => {
        if (isAuthedInternalUser !== null) {
          if (isAuthedInternalUser) {
            return true;
          } else {
            this.router.navigate([]);
            return false;
          }
        }
      })
    );
  }
}
